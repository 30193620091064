.landing-main-container {
  height: 92vh;
  width: 100%;
  /* background: #000; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-left-container {
  height: 100%;
  width: 50%;
  /* background: rgb(174, 5, 5); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-left-container-warp {
  height: 50%;
  width: 85%;
  /* background: rgb(5, 174, 112); */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}

.landing-heading-1 {
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 900;
  font-size: var(--font-size-heading-1);
  color: #000000;
}

.landing-heading-2 {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 800;
  font-size: var(--font-size-content-1);
  color: var(--font-color-3);
}

.landing-heading-3 {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 300;
  font-size: var(--font-size-2);
  color: var(--font-color-3);
}

.landing-getsupport {
  background: #e64848;
  border-radius: 7px;
  border: none;
  padding: 5px 20px 5px 20px;
  font: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-4);
  color: var(--secondary-font-color);
  cursor: pointer;
}
.landing-right-container {
  height: 100%;
  width: 50%;
  /* background: rgb(5, 174, 174); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-relax {
  height: 80%;
  width : auto;
  cursor: grab;
  perspective: 800px;
}

.landing-relax:active {
  cursor: grabbing;
}

@media only screen and (max-device-width: 760px) {
  .landing-main-container {
    flex-direction: column;
  }
  .landing-left-container {
    height: 50%;
    width: 100%;
  }
  .landing-right-container {
    height: 50%;
    width: 100%;
  }
  .landing-relax {
    width: 90%;
  }
  .landing-left-container-warp {
    height: 100%;
    width: 100%;
    align-items: center;
  }
  .landing-heading-2 {
    text-align: center;
  }
  .landing-heading-3 {
    text-align: center;
  }
}

@media only screen and (max-device-width: 420px) {
}
